// import logo_big from '../Assets/logo_big.png'
import { BsWhatsapp } from "react-icons/bs";
import { LuInstagram } from "react-icons/lu";
import { FaFacebook } from "react-icons/fa";
import './Footer.css'
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-logo">
            {/* <img src={logo_big} alt="" /> */}
            <h1>Maunacil Paints and Chemicals</h1>
        </div>

        <ul className="footer-menu">
            <Link style={{ textDecoration: 'none'}} to={'/'}><li>Home</li></Link>
            <Link style={{ textDecoration: 'none'}}  to={'/about-us'}><li>About us</li></Link>
            <Link style={{ textDecoration: 'none'}} to={'/service'}><li>Service</li></Link>
            <Link style={{ textDecoration: 'none'}} to={'/products'}><li>Products</li></Link>
            <Link style={{ textDecoration: 'none'}} to={'/contact'}><li>Contact us</li></Link>
        </ul>
        <div className="footer-social-icon">
            <div className="footer-social-icon-item">
               <LuInstagram size={30} />
            </div>
            <div className="footer-social-icon-item">
                <BsWhatsapp size={30} />
            </div>
            <div className="footer-social-icon-item">
              <FaFacebook size={30} />
            </div>
        </div>

        <hr/>
        <div className="copyright">
            <p> &copy; 2025 Manuacil chemical industry Ltd - All Right Reserved</p>
        </div>
    </div>
  )
}

export default Footer

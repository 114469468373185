import React, { useState } from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import { CgMenuGridR } from "react-icons/cg";
import { MdClose } from "react-icons/md";
import { RiHome9Line } from "react-icons/ri";
import { motion } from 'framer-motion'
import imageTextVariants from '../../animations/imageTextVariants';




const Navbar = () => {

    const [close,setClose] = useState(false)

  return (
    <div className='navbar'>

        {/* Navbar Top Section */}
        <div className='nav-top'>
            <h2>Maunacil Paints and Chemicals</h2>
            <div className='contacts'>
                <div className='tel'><b>Tel:</b>08035417449 </div>
                <div className='email'><b>Email:</b> maunacilpaints@gmail.com</div>
            </div>
        </div>

        {/* Navbar Menu List Items */}
        <div className='nav-menu'>

            {/* MOBILE NAVBAR SECTION */}
            <div className='nav-menu-mobile'>

                <div onClick={()=>setClose(true)} className='mobile-menu-icon-open'>
                    <CgMenuGridR size={25} />
                </div>
                {
                    close && (
                    <motion.div className='mobile-menu-item-list'
                        variants={imageTextVariants('left', 0)}
                        initial="hidden"
                        animate="visible"
                    >
                        <div onClick={()=>setClose(false)} className='mobile-menu-icon-close'>
                            <MdClose size={25} />
                        </div>
                        <Link style={{ textDecoration: 'none'}} to={'/'}><div className='mobile-menu-item home'>
                            <RiHome9Line size={25} className='home-icon' />
                                <p onClick={()=>setClose(false)}>Maunacil</p>
                                </div>
                        </Link>
                        <Link style={{ textDecoration: 'none'}} to={'/about-us'}><p onClick={()=>setClose(false)} className='mobile-menu-item'>About us</p></Link>
                        <Link style={{ textDecoration: 'none'}} to={'/service'}><p onClick={()=>setClose(false)} className='mobile-menu-item'>Service</p></Link>
                        <Link style={{ textDecoration: 'none'}} to={'/products'}><p onClick={()=>setClose(false)} className='mobile-menu-item'>Products</p></Link>
                        <Link style={{ textDecoration: 'none'}} to={'/contact'}><p onClick={()=>setClose(false)} className='mobile-menu-item'>Contact us</p></Link>
                    </motion.div>
                    )
                }
            </div>


            {/* DESKTOP NAVBAR SECTION */}
            <div className='nav-menu-desktop'>
                <div className='navbar-menu-item-list'>
                    <Link style={{ textDecoration: 'none'}} to={'/'}><div className='navbar-menu-item home'>
                        <RiHome9Line size={30} className='home-icon' />
                            <p>Maunacil</p>
                            </div>
                    </Link>
                    <Link style={{ textDecoration: 'none'}} to={'/about-us'}><p className='navbar-menu-item'>About us</p></Link>
                    <Link style={{ textDecoration: 'none'}} to={'/service'}><p className='navbar-menu-item'>Service</p></Link>
                    <Link style={{ textDecoration: 'none'}} to={'/products'}><p className='navbar-menu-item'>Products</p></Link>
                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><p className='navbar-menu-item'>Contact us</p></Link>
                </div>
            </div>
           
        </div>
    </div>
  )
}

export default Navbar

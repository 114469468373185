import React from 'react'
import banner from '../Assets/banner.jpg'
import './CSS/Contact.css'

const Contact = () => {
  return (
    <div className='contact'>


        <div className='right-container'>
            <div className='top'>
                <div className='tel'>
                  <h2>133 Obohai:</h2>
                  <p>09028211751 || 08035417449</p>
                </div>
                <div className='tel'>
                  <h2 className='top-h2'>112 power plaza:</h2>
                  <p>08181620199 || 09099258000</p>
                </div>
            </div>

            <div className='middle'>
                <div className='email-box'>
                  <h2>System Admin: </h2>
                  <p>admin@maunacilpaintsandchemicals.com</p>
                </div>
                <div className='email-box'>
                  <h2>Biz Desk: </h2>
                  <p>info@maunacilpaintsandchemicals.com</p>
                </div>
            </div>

            <div className='bottom'>
                <div className='address'>
                    <h2>Shop:</h2>
                    <p>112 Power Plaza Aba Abia State</p>
                </div>

                <div className='address'>
                    <h2 className='top-h2'>Branch Office:</h2>
                    <p>Shop 66, 62 Power Plaza Aba || 133 Obohia Road Aba</p>
                </div>
            </div>
        </div>

        <div className='left-container'>
           <img src={banner} alt='contact page banner' />
        </div>

    </div>
  )
}

export default Contact

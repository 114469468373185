import React from 'react'
import './CSS/Service.css'
import { Link } from 'react-router-dom'
import factory_image from '../../src/Assets/factory.jpg'
import paint_chemical from '../../src/Assets/paint-chemical.png'
import { GrPaint } from "react-icons/gr";
import { FaArrowRight } from "react-icons/fa6";
import { TfiPaintBucket } from "react-icons/tfi";
import { LuCalendarRange } from "react-icons/lu";
import { TbBrandDatabricks } from "react-icons/tb";
import { FcApproval } from "react-icons/fc";
import { FaSteam } from "react-icons/fa";
import { MdWorkspacePremium } from "react-icons/md";
import { MdInvertColors } from "react-icons/md";
import { TiWeatherDownpour } from "react-icons/ti";
import { FaArrowRightLong } from "react-icons/fa6";
import { ImLab } from "react-icons/im";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { TbArrowGuideFilled } from "react-icons/tb";
import { IoIosTimer } from "react-icons/io";
import { CgArrangeBack } from "react-icons/cg";
import { MdFactory } from "react-icons/md";

const Services = () => {
  return (
    <div className='service'>

       <div className='production-service-section'>

         <img width={1500} height={500} src={factory_image} alt='Our Painting and service' />

         <div className='production-section-container'>
            
            <div className='production-title'>
               <GrPaint size={35} />
               <h1>Paints Manufacturing</h1>
            </div>
            
            <div className='production-card-container'>

               <div className='production-card'>
                  <div className='production-card-icon'> 
                     <TfiPaintBucket size={35} />
                  </div>
                  <h2>Paint Production</h2>
                  <p>At Maunacil Chemical Industrial Ltd, we take pride 
                     in delivering premium-quality paints and coatings that 
                     meet the highest standards of durability, aesthetics, 
                     and functionality. As a trusted manufacturer in the paint 
                     industry since 2008, we are committed to innovation, 
                     precision, and customer satisfaction.</p>
                     <div className='production-card-arrow one'>
                        <FaArrowRight size={25} style={{color: "#8bc34a"}} />
                     </div>
               </div>

               <div className='production-card'>
                  <div className='production-card-icon'> 
                     <LuCalendarRange size={35} style={{color: "#8bc34a"}} />
                  </div>
                  <h2>Diverse Product Range</h2>
                  <p>We produce wide variety of paints tailored to suit different surfaces and needs, including:
                           Decorative paints for vibrant and stylish interiors.
                           Protective coatings for durability and weather resistance.
                           Specialty paints for customized applications.</p>
                     <div className='production-card-arrow two'>
                        <FaArrowRight size={25} style={{color: "#8bc34a"}} />
                     </div>
               </div>

               <div className='production-card'>
                  <div className='production-card-icon'> 
                        <TbBrandDatabricks size={35} />
                  </div>
                  <h2>Our Brands:</h2>
                  <p>Our signature paint brands, such as Maunacil Luminate, 
                     MTM, and LSP 90, are known for their excellent finish, 
                     longevity, and wide color options. We embrace the latest advancements 
                     in paint technology to develop unique formulations. Whether 
                     you need modern matte finishes or glossy textures, we have the solution.</p>
                  <div className='production-card-arrow three'>
                     <FaArrowRight size={25} style={{color: "#8bc34a"}} />
                  </div>
               </div>

               <div className='production-card'>
                  <div className='production-card-icon'> 
                        <FcApproval size={35} />
                  </div>
                  <h2>Quality Assurance:</h2>
                  <p>Every batch of paint is manufactured using premium 
                     raw materials and advanced techniques, ensuring consistency, 
                     eco-friendliness, and superior performance.</p>
                  <div className='production-card-arrow four'>
                     <FaArrowRight size={25} style={{color: "#8bc34a"}} />
                  </div>
               </div>

            </div>

         </div>

       </div>

       <div className='painting-service-section'>

            <div className='painting-section-container'>

               <div className='painting-section-title'>
                  <h1>Painting Service</h1>
                  <p>Our house painting services is designed to transform your 
                  home into a masterpiece. With a commitment to quality 
                  and attention to detail, we provide tailored solutions 
                  to meet your aesthetic and functional needs.</p>
               </div>

               <div className='painting-section-card-container'>

                  <div className='painting-card one'>
                      <div className='painting-card-icon one'> 
                         <FaSteam size={30} style={{ color: "#8bc34a"}} />
                      </div>
                      <h2>Expert Craftsmanship</h2>
                      <p>Our team of skilled painters ensures a flawless 
                        finish with precision and professionalism, delivering 
                        exceptional results every time.</p>
                      <button className='one'>touch of quality..</button>
                  </div>

                  <div className='painting-card two'>
                      <div className='painting-card-icon two'> 
                         <MdWorkspacePremium size={30} style={{ color: "#8bc34a"}} />
                      </div>
                      <h2>Premium Quality Products</h2>
                      <p>We use our own high-quality paint brands like 
                        Maunacil Luminate, MTM, and LSP 90, known for 
                        their vibrant colors, durability, and superior performance.</p>
                      <button className='two' >touch of quality..</button>
                  </div>

                  <div className='painting-card three'>
                       <div className='painting-card-icon three'> 
                          <MdInvertColors size={30} style={{ color: "#8bc34a"}} />
                       </div>
                       <h2>Customizable Solutions</h2>
                       <p>Whether it's interior or exterior painting, 
                         we offer a wide range of colors, textures, and 
                         finishes to suit your preferences and style.</p>
                       <button className='three'>touch of quality..</button>
                   </div>

                  <div className='painting-card four'>
                      <div className='painting-card-icon four'> 
                         <TiWeatherDownpour size={30} style={{ color: "#8bc34a"}} />
                      </div>
                      <h2>Durable and Long-Lasting</h2>
                      <p>Our paints are designed to withstand various 
                        weather conditions, ensuring your home remains 
                        beautiful for years to come.</p>
                      <button className='four'>touch of quality..</button>
                  </div>

               </div>

               <div className='painting-service-caption'>
                  <h2>Our Commitment</h2>
                  <p>At Maunacil, we believe every home deserves to look its best. 
                    With our expertise, premium products, and dedication to excellence, 
                    we turn your vision into reality—one brushstroke at a time. 
                    Let us bring life to your walls and create a space you'll love to live in.</p>
                  <Link style={{ textDecoration: 'none'}} to={'/contact'}><p className='painting-service-caption-button'>Get in touch with us..</p></Link> 
              </div>

            </div>

       </div>

       <div className='chemical-sale-service-section'>

            <img width={1200} height={700} src={paint_chemical} alt='Our Products and service' />
            <div className='chemical-sale-service-container'>
               <h1 className='chemical-sale-title'>Paint Chemical sales</h1>

               <div className='chemical-sale-card-container'>

                  <div className='chemical-sale-card one'>
                      <div className='chemical-sale-card-icon'> 
                         <ImLab size={30}  style={{ color: "#fff"}}  />
                      </div>
                      <div className='chemical-sale-card-text'>
                        <h2>Chemical sales</h2>
                        <p>Maunacil Chemical Industrial Ltd is also specialize in the 
                          sale and distribution of top-quality paint chemicals, 
                          catering to manufacturers, contractors, and businesses 
                          in the coatings industry. Our extensive range of chemicals 
                          is carefully selected to ensure high performance, durability, 
                          and superior results.</p>
                          <div className='chemical-sale-card-arrow-icon one'>
                              <FaArrowRightLong style={{ color: "#8bc34a"}} />
                          </div>
                      </div>
                  </div>

                  <div className='chemical-sale-card two'>
                     <div className='chemical-sale-card-icon'> 
                        <VscWorkspaceTrusted size={30}  style={{ color: "#fff"}}  />
                     </div>
                     <div className='chemical-sale-card-text'>
                        <h2>Quality You Can Trust:</h2>
                        <p>Our paint chemicals are sourced from reliable 
                        suppliers and meet international quality standards, 
                        ensuring optimal performance in every application.</p>
                        <div  className='chemical-sale-card-arrow-icon two'>
                            <FaArrowRightLong style={{ color: "#8bc34a"}} />
                        </div>
                     </div>
                  </div>

                  <div className='chemical-sale-card three'>
                      <div className='chemical-sale-card-icon'> 
                         <TbArrowGuideFilled size={30}  style={{ color: "#fff"}}  />
                      </div>
                      <div className='chemical-sale-card-text'>
                        <h2>Expert Guidance</h2>
                        <p>Our team of professionals is available to provide 
                          technical advice and recommendations, helping you 
                          select the right chemicals for your specific needs.</p>
                          <div  className='chemical-sale-card-arrow-icon three'>
                             <FaArrowRightLong style={{ color: "#8bc34a"}} />
                          </div>
                      </div>
                  </div>

                  <div className='chemical-sale-card four'>
                      <div className='chemical-sale-card-icon'> 
                         <IoIosTimer size={30}  style={{ color: "#fff"}}  />
                      </div>
                      <div className='chemical-sale-card-text'>
                        <h2>Timely and Reliable Supply</h2>
                        <p>With a well-organized distribution network, 
                          we ensure timely delivery of chemicals, 
                          even for large-scale projects.</p>
                          <div  className='chemical-sale-card-arrow-icon four'>
                              <FaArrowRightLong style={{ color: "#8bc34a"}} />
                          </div>
                      </div>
                  </div>

                  <div className='chemical-sale-card five'>
                      <div className='chemical-sale-card-icon'> 
                         <CgArrangeBack size={30}  style={{ color: "#fff"}} />
                      </div>
                      <div className='chemical-sale-card-text'>
                        <h2>Comprehensive Product Range</h2>
                        <p>We provide a wide variety of paint chemicals, including:
                          Binders and resins for improved adhesion and durability.
                          Pigments and dyes for vibrant, consistent colors.
                          Additives for enhanced properties such as gloss, drying time, and texture.
                          Solvents and thinners for application versatility.</p>
                          <div  className='chemical-sale-card-arrow-icon five'>
                              <FaArrowRightLong style={{ color: "#8bc34a"}} />
                          </div>
                      </div>
                  </div>

                  <div className='chemical-sale-card six'>
                      <div className='chemical-sale-card-icon'> 
                         <MdFactory size={30} style={{ color: "#fff"}} />
                      </div>
                      <div className='chemical-sale-card-text'>
                        <h2>Industries We Serve:</h2>
                        <p>Our paint chemicals cater to a variety of industries, including:
                          Paint and coatings manufacturers
                          Construction, renovation, and industrial coatings</p>
                          <div  className='chemical-sale-card-arrow-icon six'>
                              <FaArrowRightLong style={{ color: "#8bc34a"}} />
                          </div>
                      </div>
                  </div>

               </div>

            </div>
       </div>

    </div>
  )
}

export default Services

import React from 'react'
import './CSS/Product.css'
import { Link } from 'react-router-dom'
import { MdOutlineStar } from "react-icons/md";
import product_image from '../../src/Assets/product.png'
import brilliant_satin from '../../src/Assets/platinum/brilliant-satin.png'
import acry_premier from '../../src/Assets/platinum/acry-premier.png'
import brilliant_soft from '../../src/Assets/platinum/brilliant-soft.png'
import classic_matt from '../../src/Assets/platinum/classic-matt.png'
import acrylique_premier from '../../src/Assets/platinum/acrylique-premier.png'
import lux_emulsion from '../../src/Assets/platinum/lux-emulsion.png'
import protect_seal from '../../src/Assets/platinum/protect-seal.png'
import premium_silk from '../../src/Assets/platinum/premium-silk.png'
import platin_matt from '../../src/Assets/platinum/platin-matt.png'
import { FaPaintRoller } from "react-icons/fa";
import { LuPaintBucket } from "react-icons/lu";
import { PiPaintBrushBroadBold } from "react-icons/pi";
import { MdInvertColors } from "react-icons/md";


const Products = () => {
  return (
    <div className='product'>
         <div className='product-container'>
              <div className='image-container'>
                  <img width={1430} height={400} src={product_image} alt='products banner' />
                  <div className='image-overlay'>
                      <p>Maunacil Decorative Products Showcase</p>
                  </div>
              </div>

              <div className='platinum-product-container'>
                  <div className='product-headline'>Platinum Products Lines</div>

                  <div className='product-card-container'>

                      <div className='product-card one'>
                          <div className='product-card-left'>
                              <img width={110} src={brilliant_satin} alt='Brilliant Satin' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Brilliant Satin</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 400 sq ft per gallon
                                    Finish: Matte
                                    Durability: 10 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                   <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <FaPaintRoller size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                        <LuPaintBucket size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card two'>
                          <div className='product-card-left'>
                              <img width={110} src={acry_premier} alt='Acry Premier' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Acry Premier</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card three'>
                          <div className='product-card-left'>
                              <img width={110} src={brilliant_soft} alt='Brilliant Soft' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Brilliant Soft</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card four'>
                          <div className='product-card-left'>
                              <img width={110} src={classic_matt} alt='Classic Matt' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Classic Matt</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card five'>
                          <div className='product-card-left'>
                              <img width={110} src={acrylique_premier} alt='Acrylic Premier' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Acrylic Premier</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card six'>
                          <div className='product-card-left'>
                              <img width={110} src={lux_emulsion} alt='Lux Emulsion' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Lux Emulsion</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card seven'>
                          <div className='product-card-left'>
                              <img width={110} src={protect_seal} alt='Protect Seal' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Protect Seal</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card eight'>
                          <div className='product-card-left'>
                              <img width={110} src={premium_silk} alt='Premium Silk' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Premium Silk</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                      <div className='product-card nine'>
                          <div className='product-card-left'>
                              <img width={110} src={platin_matt} alt='Platin Matt' />
                          </div>
                          <div className='product-card-right'>
                                <h2 className='product-title'>Platin Matt</h2>
                                <p className='product-desc'>
                                    <h3>Specification: </h3>
                                    Coverage: 350 sq ft per gallon
                                    Finish: Flat
                                    Durability: 5 years
                                    Application: Brush, Roller, or Spray
                                </p>
                                <div className='horizontal-line'></div>
                                <div className='product-rating-and-btn'>
                                    <div className='product-rating'>
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                        <MdOutlineStar />
                                    </div>
                                    <Link style={{ textDecoration: 'none'}} to={'/contact'}><button>Buy Now</button></Link>
                                </div>

                                <div className='accessory'>
                                    <div className='accessory-item'>
                                        <div><FaPaintRoller size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <div><LuPaintBucket size={15} className='middle-feature-text-icon' /></div>
                                    </div>

                                    <div className='accessory-item'>
                                        <PiPaintBrushBroadBold  size={15} />
                                    </div>

                                    <div className='accessory-item'>
                                       <MdInvertColors size={15}  />
                                    </div>
                                    
                                </div>
                          </div>
                      </div>

                  </div>

              </div>
             
         </div>
    </div>     
)
}
export default Products

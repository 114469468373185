import React from 'react'
import './CSS/AboutUs.css'
import paint_image from '../Assets/bue-transparent.png'
import { motion } from 'framer-motion'
import imageTextVariants from '../animations/imageTextVariants'


const AboutUs = () => {
  return (
    <div className='about'>
        <div className='about-container'>
              <div className='about-container-left'>
                  <div className='about-container-item-left'>
                      <h2 className='item-title'>Manuacil chemical industrial Ltd</h2>
                      <p className='item-desc'>
                            Manuacil Chemical Industrial Ltd has been a registered
                            company since 2008. We specialize in the manufacturing 
                            of paints and coatings and also trade in various chemicals.
                      </p>

                      <h2 className='item-title'>Our Paint Brands:</h2>
                      <p className='item-desc'> Maunacil Luminate  || Maunacil MTM  ||  LSP 90</p>

                      <h2 className='item-title'>Bond Products:</h2>
                      <p className='item-desc'> Globe Bond </p>

                      <div className='input-container'>
                          <div className='input-field-1'>Transforming spaces, one stroke at a time</div>
                          <div className='input-field-2'>quality and durability</div>
                      </div>
                  </div>
              </div>

              <div className='about-container-right'>
                    <div className='about-container-item-right'>
                      <motion.div
                          variants={imageTextVariants('right', 0.7)}
                          initial="hidden"
                          animate="visible"
                      >
                          <img className='item-right-image' src={paint_image} alt='luminate paint'/> 
                      </motion.div>
                    </div>

                    <div className='item-right-2'>
                          <p>code: 10203</p>
                    </div>
              </div>
        </div>
    </div>
  )
}

export default AboutUs

import { createBrowserRouter } from 'react-router-dom'
import App from '../../App'
import Home from '../../pages/Home'
import AboutUs from '../../pages/AboutUs'
import Service from '../../pages/Service'
import Contact from '../../pages/Contact'
import Products from '../../pages/Product'

const Router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: '',
                element: <Home/>,
            },
            {
                path: '/about-us',
                element: <AboutUs/>
            },
            {
                path: '/service',
                element: <Service/>
            },
            {
                path: '/products',
                element: <Products/>,
            },
            {
                path: '/contact',
                element: <Contact/>
            }
        ]
    }
])

export default Router
const imageTextVariants = (direction, delay) => {
    return {
        hidden: {
            y: direction === 'up' ? '-100vh' : direction === 'down' ? '100vh' : 0,
            x: direction === 'left' ? '-100vw' : direction === 'right' ? '100vw' : 0,
          },
          visible: {
            x: 0,
            y: 0,
            transition: {
              delay: delay,  
              type: 'spring',
              stiffness: 40, 
              duration: 5.0
            }
          }
    }
}

  



export default imageTextVariants
